export {};

type Cookie = {
	Name: string;
	Host: string;
};

declare let Optanon: {
	GetDomainData: () => {
		Groups: {
			CustomGroupId: string;
			Cookies: Cookie[];
			Hosts: { Cookies: Cookie[] }[];
		}[];
	};
	OnetrustActiveGroups: string;
};

declare let OnetrustActiveGroups: string;
declare global {
	interface Window {
		OptanonWrapper: () => void;
	}
}

const allGroups = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'];
const cookieNames = document.cookie
	.split(';')
	.map((cookie) => cookie.split('=')[0].trim());

window.OptanonWrapper = () => {
	console.log('OptanonWrapper');

	const activeGroups = OnetrustActiveGroups.split(',').filter(Boolean);

	const inactiveGroups = allGroups.filter(
		(group) => !activeGroups.includes(group)
	);
	const otDeleteCookie = () => {
		console.log('otDeletedGrpIds', inactiveGroups);
		Optanon.GetDomainData()
			.Groups.filter((grp) => inactiveGroups.includes(grp.CustomGroupId))
			.forEach((grp) => {
				grp.Cookies.forEach((cookie) => {
					erase(cookie.Name);
				});

				grp.Hosts.forEach((host) => {
					host.Cookies.forEach((cookie) => {
						erase(cookie.Name);
					});
				});
			});
	};

	// This pattern matches strings with wildcards like "foo_*" or "foo_xxx"
	// and captures the name and the wildcard(s) separately.
	// Important: This is not a general purpose glob pattern matcher. It only
	// supports a single '*' (matching a variable number of characters)
	// or multiple 'x' wildcards (matching a fixed number of characters)
	// and only at the end of the string.
	const globPattern = /(\S+?)(\*|x+)$/;

	// Erase cookie by name or glob pattern
	const erase = (nameOrGlob: string) => {
		const globMatch = nameOrGlob.match(globPattern);

		if (!globMatch) {
			// If the glob pattern doesn't match, we assume it's a cookie name
			// and delete it if it exists
			if (!cookieNames.includes(nameOrGlob)) return;

			eraseCookie(nameOrGlob);
			return;
		}

		const name = globMatch[1];
		const glob = globMatch[2];
		cookieNames.forEach((cookieName) => {
			if (cookieName.startsWith(name)) {
				if (glob === '*') {
					eraseCookie(cookieName);
				} else if (cookieName.length === name.length + glob.length) {
					eraseCookie(cookieName);
				}
			}
		});
	};

	//Delete cookie
	function eraseCookie(name: string) {
		console.log('eraseCookie', name);

		//Delete root path cookies
		const domainName = window.location.hostname;
		document.cookie =
			name + '=; Max-Age=-99999999; Path=/;Domain=' + domainName;
		document.cookie =
			name + '=; Max-Age=-99999999; Path=/;Domain=.bruun-rasmussen.dk';
		document.cookie = name + '=; Max-Age=-99999999; Path=/;';
		//Delete LSO in case LSO being used, cna be commented out.
		localStorage.removeItem(name);
		//Check for the current path of the page
		const pathArray = window.location.pathname.split('/');
		//Loop through path hierarchy and delete potential cookies at each path.
		for (let i = 0; i < pathArray.length; i++) {
			if (pathArray[i]) {
				//Build the path string from the Path Array e.g /site/login
				const currentPath = pathArray.slice(0, i + 1).join('/');
				document.cookie =
					name +
					'=; Max-Age=-99999999; Path=' +
					currentPath +
					';Domain=' +
					domainName;
				document.cookie =
					name + '=; Max-Age=-99999999; Path=' + currentPath + ';';
				//Maybe path has a trailing slash!
				document.cookie =
					name +
					'=; Max-Age=-99999999; Path=' +
					currentPath +
					'/;Domain=' +
					domainName;
				document.cookie =
					name + '=; Max-Age=-99999999; Path=' + currentPath + '/;';
			}
		}
	}

	otDeleteCookie();
};
